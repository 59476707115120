/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import CN from 'classnames'

import { Header } from 'components/common/Header'
import { setCardList, setIsLoading, setUserDetails } from 'store/reducers/user/userSlice'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import { useGetAllCards, useGetVerifyUser } from 'framework/api/methods'
import { useLocation, useNavigate } from 'react-router-dom'
import { InfoModal } from 'components/molecules'


export interface MainLayoutProps {
  hasSubHeading?: boolean
  [x: string]: any
}

export const MainLayout: FC<MainLayoutProps> = ({
  children,
}: MainLayoutProps) => {

  const token = localStorage.getItem('token')
  const dispatch = useDispatch()
  const navigate = useNavigate()
  
  const { isShowInfoModal }: any = useSelector<any>((state) => state.header);

  
  const location = useLocation();

  const notify = (message: any, type: any) => {
    if (type === 'error') {
      toast.error(message, {
        position: 'bottom-center',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
    }
    if (type === 'success') {
      toast.success(message, {
        position: 'bottom-center',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
    }
  }

  

  const onSuccess =(data: any)=>{
    dispatch(setCardList(data))
  }

  const onError =(data: any)=>{
    //
  }

  const onSuccessVerifyToken =(data: any)=>{
    dispatch(setUserDetails({name: data?.userData}))
  }

  const onErrorVerifyToken =(data: any)=>{
   notify('Session expired.', 'error')
   setTimeout(()=>{
    navigate('/auth/login')
    localStorage.clear()
   }, 2000);
   
  }


  /** Get all cards API */
  const { refetch:getVerifyToken } = useGetVerifyUser(onSuccessVerifyToken,onErrorVerifyToken)
  
  /** Get all cards API */
  const { refetch:getCardList, isLoading:getCardListIsLoading, isRefetching:getCardListIsRefetching } = useGetAllCards(onSuccess,onError)
  
  useEffect(() => {
    token && getVerifyToken()
  }, [token])


  useEffect(() => {
    token && getCardList()
  }, [token])

  useEffect(() => {
   dispatch(setIsLoading(getCardListIsLoading || getCardListIsRefetching))
  }, [getCardListIsLoading, getCardListIsRefetching])
  

  return (
    <>
      <div className={CN('w-full h-full min-h-full md:hidden sm:hidden',{
        'back-drop-grad' : location.pathname === '/custom',
        'back-drop':location.pathname !== '/custom',
      })}>
        <Header/>
        <div
          className={CN(
            'main-layout__content z-10 fixed top-[60px] h-full min-h-full w-full overflow-y-auto',
          )}>
          {children}
        </div>

        <InfoModal isOpen={isShowInfoModal}/>
        
        <ToastContainer
            position='bottom-center'
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme='colored'
            className='toast-container'
          />
      </div>
    </>
  )
}

MainLayout.defaultProps = {
  hasSubHeading: true,
}

export default MainLayout
