import { Button, Input , PopOut } from "components/atoms"
import { useRequestOTP } from "framework/api/methods";
import { useEffect, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import { toast } from 'react-toastify'

export const WelcomePage= () => {
  const location = useLocation();
  const navigate = useNavigate()
  const [name, setName] = useState('')
  const [phone, setPhone] = useState('')
  const notify = (message: any, type: any) => {
    if (type === 'error') {
      toast.error(message, {
        position: 'bottom-center',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
    }
    if (type === 'success') {
      toast.success(message, {
        position: 'bottom-center',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
    }
  }
  useEffect(() => {
    setPhone(location.state?.phone)
  }, [location])
  
  const { mutate: requestOTPMutate, isLoading: requestOTPIsLoading } = useRequestOTP()

   // handle input change
   const handleChange = (e:any) => {
    setName(e.target.value)
  }

  // function to request Otp for registered / unregistered users
  const handleRequestOTP=()=> {
    requestOTPMutate({
      mobile: phone
    }, {
    onSuccess: ({ data: successData }: any) => {
      navigate('/auth/request-otp',{ state: { phone, name, ref:successData?.serverRef, isNewUser: true} })
      notify(successData?.message || 'OTP has been sent successfully', 'success')
    },
    onError: ({ response: { data: errData } }: any) => {
      if(errData?.message){
        notify(errData?.message || 'Something went wrong.', 'error')
      }
    },
  })
}

  return (
    <div className='w-full flex flex-col justify-start items-start p-5'>
      {/* back btn */}
      <div className="w-full flex">
        <i className="ri-arrow-left-line text-[24px]" 
        onClick={()=>{
          navigate('/auth/login')
        }}
        />
      </div>

      <div className="flex w-full flex-col pt-[63px] text-[#01060D] text-left">
        <div className="headings-font text-[32px] leading-[45px] font-medium pb-[1px]">Welcome!</div>
        <div className="captions-font text-[14px] font-light">Enter the below details to create your profile.</div>
      </div>

      <div className="flex flex-col w-full gap-y-[28px] mt-[86px]">
        <Input type='text' value={name} onChange={handleChange} labelValue='Name' />
      </div>

      <Button 
        isLoading={requestOTPIsLoading}
        disabled={!name || !phone}
        appearance="default" 
        className='mt-10' 
        onClick={()=>{
          handleRequestOTP()
        }}
      >
        Next
      </Button>

      
     
    </div>
  )
}

export default WelcomePage
