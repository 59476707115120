/* Layouts */
import { AuthLayout } from 'components/layouts/AuthLayout'
import { MainLayout } from 'components/layouts/MainLayout'
import { GetStarted } from 'components/pages/auth/GetStarted'

/* Page components */
import { Login } from 'components/pages/auth/Login'
import { RequestOtp } from 'components/pages/auth/RequestOtp'
import { WelcomePage } from 'components/pages/auth/WelcomePage'
import { CustomizedPage } from 'components/pages/CustomizedPage'
import { Home } from 'components/pages/Home'
import { MenuPage } from 'components/pages/MenuPage'
import { MyList } from 'components/pages/MyList'
import { PersonalizePage } from 'components/pages/PersonalizePage'
import { PreviewPage } from 'components/pages/PreviewPage'
import { TermAndConditions } from 'components/pages/TermAndConditions'


interface Route {
  path: string
  component?: any
  layout?: any
  redirectTo?: string
  isSecured?: boolean
  isAuthScreen?: boolean
}

export const routes: Route[] = [
  {
    path: '/auth',
    redirectTo: '/auth/login',
  },
  {
    path: '/',
    component: GetStarted,
    isAuthScreen: false,
  },
  {
    path: '/auth/register',
    component: WelcomePage,
    layout: AuthLayout,
    isAuthScreen: true,
  },
  {
    path: '/auth/login',
    component: Login,
    layout: AuthLayout,
    isAuthScreen: true,
  },
  {
    path: '/auth/request-otp',
    component: RequestOtp,
    layout: AuthLayout,
    isAuthScreen: true,
  },
  {
    path: '/home',
    component: Home,
    layout: MainLayout,
    isSecured: true,

  },
  {
    path: '/edit',
    component: PersonalizePage,
    layout: MainLayout,
    isSecured: true,

  },
  {
    path: '/menu',
    component: MenuPage,
    layout: MainLayout,
    isSecured: true,

  },
  {
    path: '/preview',
    component: PreviewPage,
    layout: MainLayout,
    isSecured: true,

  },
  {
    path: '/custom',
    component: CustomizedPage,
    layout: MainLayout,
    isSecured: true,
  },
  {
    path: '/my-list',
    component: MyList,
    layout: MainLayout,
    isSecured: true,
  },
  {
    path: '/term-and-conditions',
    component: TermAndConditions,
  },
]

export default routes
