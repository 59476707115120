import getStarted from 'assets/getStarted.png'
import dialogLogo from 'assets/dialogLogo.png'
import spLogo from 'assets/spLogo.png'
import { Button } from 'components/atoms'
import { useNavigate } from 'react-router-dom'
import { useGetVerifyUser } from 'framework/api/methods'
import { privacyLink } from 'config/framework.config'


export const GetStarted= () => {
  const navigate = useNavigate()

  const onSuccessVerifyToken =()=>{
    navigate('/home')
  }

  const onErrorVerifyToken =()=>{
    localStorage.clear()
    navigate('/auth/login')
  }


  /** Get all cards API */
  const { refetch:getVerifyToken, isRefetching , isLoading } = useGetVerifyUser(onSuccessVerifyToken,onErrorVerifyToken)
  
  return (
    <div className='flex flex-1 h-auto min-h-full w-full bg-black md:bg-white'>
      
      <div className='flex h-full bg-black w-full flex-col md:hidden sm:hidden'>
        <div className='relative inline-block z-[1]'>
        <img src={getStarted} alt='gt' className='w-screen h-auto'/>
        <div className='absolute top-0 left-0 w-full h-full bg-[#01060D] bg-opacity-[30%]'></div>
        </div>


        <div className='flex w-full h-full -mt-[95px] z-[2]'>
          <div className="flex flex-col flex-1 justify-end items-center w-full h-full text-white px-4 py-10">
            <div className='text-[40px] leading-[40px] font-semibold text-left w-full'>Transform your
              <br/>
              wishes into 
              <br/>
              greeting cards
            </div>
            
            <div className='text-[14px] pt-[18px] text-left captions-font font-extralight'>Craft heartfelt greetings with thoughtful messages for those dearest to you, brought to life by AI-powered image generation.
            </div>

            <div className='flex flex-col justify-center items-center w-full text-[10px] pt-[24px]'>
              Powered By

              <div className='flex w-full gap-x-[22px] justify-center items-center -mt-[5px]'>
                <img src={dialogLogo} alt='gt' className='w-auto h-fit'/>
                <img src={spLogo} alt='gt' className='w-[80px] h-auto'/>
              </div>
            </div>

            <Button 
            className='mt-[10px]' 
            appearance='gradient'
            isLoading={isLoading||isRefetching}
            onClick={()=>{
              getVerifyToken()
            }}>Get started</Button>

            <div className='text-white text-[10px] pt-4 text-center w-full captions-font underline' onClick={()=>{
              navigate('/term-and-conditions')
            }}>T&C | Privacy Notice</div>

            
          </div>
        </div> 
      </div>
    </div>
  )
}

export default GetStarted
