import { Button, Input , PopOut } from "components/atoms"
import { useCheckUser, useVerifyOTP, useVerifyOTPWIthRegiter } from "framework/api/methods"
import { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { useLocation, useNavigate } from "react-router-dom"
import { setUserDetails } from "store/reducers/user/userSlice"
import { toast } from 'react-toastify'

export const RequestOtp = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const location = useLocation();
  const [otp, setOtp] = useState('')


  const { mutate: verifyOTPWIthRegiterMutate, isLoading: verifyOTPWIthRegiterIsLoading } = useVerifyOTPWIthRegiter()
  const { mutate: verifyOTPMutate, isLoading: verifyOTPIsLoading } = useVerifyOTP()
  const notify = (message: any, type: any) => {
    if (type === 'error') {
      toast.error(message, {
        position: 'bottom-center',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
    }
    if (type === 'success') {
      toast.success(message, {
        position: 'bottom-center',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
    }
  }
  // request OTP for un registered users
  const handleRequestOTPWithRegister = () => {
    verifyOTPWIthRegiterMutate({
      data: otp,
      serverRef: location.state?.ref,
      name: location.state?.name
    }, {
      onSuccess: ({ data: successData }: any) => {
        localStorage.setItem('token', successData?.token)
        dispatch(setUserDetails({ name: successData.data }))
        notify(successData?.message || 'User Verified!', 'success')
        navigate('/home')
      },
      onError: ({ response: { data: errData } }: any) => {
        if (errData?.message) {
          notify(errData?.message || 'Something went wrong.', 'error')
        }
      },
    })
  }

  const { mutate: resendOTPMutate, isLoading: resendOTPIsLoading } = useCheckUser()

  //resend otp
  const resendOTP = () => {
    resendOTPMutate({
      mobile: location.state?.phone
    }, {
      onSuccess: ({ data: successData }: any) => {
        notify(successData?.message || 'OTP has been sent successfully', 'success')
      
      },
      onError: ({ response: { data: errData } }: any) => {
        if (errData?.message) {
        notify(errData?.message || 'Something went wrong.', 'error')
        }
      },
    })
  }

  // request OTP for registered users
  const handleRequestOTP = () => {
    verifyOTPMutate({
      data: otp,
      serverRef: location.state?.ref
    }, {
      onSuccess: ({ data: successData }: any) => {
        localStorage.setItem('token', successData?.token)
        dispatch(setUserDetails({ name: successData.data }));
        notify(successData?.message || 'User Verified!', 'success')
        navigate('/home')
      },
      onError: ({ response: { data: errData } }: any) => {
        if (errData?.message) {
          notify(errData?.message || 'Something went wrong.', 'error')
        }
      },
    })
  }

  // handle input change
  const handleChange = (e:any) => {
    setOtp(e.target.value)
  }

  return (
    <div className='w-full flex flex-col justify-start items-start p-5'>
      {/* back btn */}
      <div className="w-full flex">
        <i className="ri-arrow-left-line text-[24px]" onClick={() => {
          navigate(-1)
        }
        } />
      </div>

      <div className="flex w-full flex-col pt-[63px] text-[#01060D] text-left">
        <div className="headings-font text-[32px] leading-[45px] font-medium pb-[1px]">OTP</div>
        <div className="captions-font text-[14px] font-light">Enter the OTP you received via SMS.</div>
      </div>

      <div className="flex flex-col w-full mt-[116px]">
        <Input value={otp}  onChange={handleChange} labelValue='OTP' />

        <Button appearance="default" className='mt-10'
         disabled={!otp}
          isLoading={verifyOTPIsLoading || verifyOTPWIthRegiterIsLoading}
          onClick={() => {
            location.state?.isNewUser ? handleRequestOTPWithRegister() : handleRequestOTP()
          }
          
          }
        >Get started</Button>

        <Button appearance="link" className='mt-4' isLoading={resendOTPIsLoading} onClick={() => {
          resendOTP()
        }}>Resend OTP</Button>
        

      </div>


    </div>
  )
}

export default RequestOtp
