/** Users */
import { combineReducers } from 'redux'
import headerReducer from 'store/reducers/header/headerSlice'
import userReducer from 'store/reducers/user/userSlice'

const appReducer = combineReducers({
  user: userReducer,
  header: headerReducer,
})

export const rootReducer = (state, action) => {
  if (action.type === 'USER_LOGOUT') {
    state = undefined
  }

  return appReducer(state, action)
}
