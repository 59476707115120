/* eslint-disable no-unused-expressions */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-empty-function */
import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  isLoading: false,
  isLogOutModalOpen: false,
  userDetails: {
    name: '',
    image:'',
    filteredCard: null
  },
  cardList: [],
  aiGeneratedUrl: null
}

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    resetToInitialState(state, action) {
      ;(state.isLoading = false), (state.userDetails = {}), (state.cardList = [])
    },

    setIsLoadingState(state, { payload }) {
      state.isLoading = payload
    },

    setUserDetailsState(state, { payload }) {
      state.userDetails = payload
    },

    setCardListState(state, { payload }) {
      state.cardList = payload
    },

    setAIGeneratedURLState(state, { payload }) {
      state.aiGeneratedUrl = payload
    },

    setUserPermissionsState(state, { payload }) {
      state.userDetails.userPermissions = payload
    },

    setLogoutModalShowState(state, action) {
      state.isLogOutModalOpen = true
    },

    setLogoutModalHideState(state, action) {
      state.isLogOutModalOpen = false
    },

  },
})

export const {
  resetToInitialState,
  setIsLoadingState,
  setUserDetailsState,
  setLogoutModalShowState,
  setLogoutModalHideState,
  setUserPermissionsState,
  setSeletectedCard,
  setCardListState,
  setAIGeneratedURLState
} = userSlice.actions

export const setToInitial =
  (values, cb = () => {}) =>
  (dispatch) => {
    dispatch(resetToInitialState(values))
    return cb(values)
  }

export const setIsLoading =
  (payload, cb = () => {}) =>
  (dispatch) => {
    dispatch(setIsLoadingState(payload))
  }

export const setUserDetails =
  (value, cb = () => {}) =>
  (dispatch) => {
    dispatch(setUserDetailsState(value))
  }

export const setUserPermissions =
  (value, cb = () => {}) =>
  (dispatch) => {
    dispatch(setUserPermissionsState(value))
  }

export const setLogoutModalShow =
  (value, cb = () => {}) =>
  (dispatch) => {
    dispatch(setLogoutModalShowState(value))
  }

export const setLogoutModalHide =
  (value, cb = () => {}) =>
  (dispatch) => {
    dispatch(setLogoutModalHideState(value))
  }

  
  export const setCardList =
  (value, cb = () => {}) =>
  (dispatch) => {
    dispatch(setCardListState(value))
  }


  export const setAIGeneratedURL =
  (value, cb = () => {}) =>
  (dispatch) => {
    dispatch(setAIGeneratedURLState(value))
  }
  


export default userSlice.reducer
