import { useMutation } from 'react-query'
import { postAPI } from 'framework/api/http'

interface VerifyOTPWIthRegiterInterface {
  data: string
  serverRef: string
  name: string
}

export const useVerifyOTPWIthRegiter = () => {
  const mutate = useMutation(({ data, serverRef, name }: VerifyOTPWIthRegiterInterface) => {
    return postAPI('/user-api/verify_otp_with_reg', {
      data,
      serverRef,
      name
    })
  })

  return mutate
}

export default useVerifyOTPWIthRegiter
