import { Button, Loader, Modal } from 'components/atoms'
import { Fragment, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useGetAllStarPoints, useGetGeneratedAI } from "framework/api/methods";
import { toast } from 'react-toastify'
import { setAIGeneratedURL } from 'store/reducers/user/userSlice';

interface CardData {
  templateID: number;
  imageURL: string;
  prompt: string;
  hiddenText: string;
  colourCode: string;
}




export const CustomizedPage = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [inputValues, setInputValues] = useState(['', '', '', '', '']); // Initial values for input fields
  const [card, setCard] = useState<CardData>();
  const [segments, setSegments] = useState<string[]>([]);
  const [finalText, setFinalText] = useState<string | undefined>('');
  const [hiddenText, setHiddenText] = useState('')
  const [isDisableBtn, setIsDisableBtn] = useState(true)
const [data, setData] = useState<any>(null)
  
  const location = useLocation();
  
  /** Get generate AI Card API **/
  const { mutate: getGeneratedAIMutate, isLoading: getAiImageLoading } = useGetGeneratedAI();

  const notify = (message: any, type: any) => {
    if (type === 'error') {
      toast.error(message, {
        position: 'bottom-center',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
    }
    if (type === 'success') {
      toast.success(message, {
        position: 'bottom-center',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
    }
  }

  // Function to generate AI
  const handleClick = () => {
    if (finalText) {
      getGeneratedAIMutate({ user_prompt: finalText+hiddenText, date_time: new Date().getTime() }, {
        onSuccess: ({ data: successData }: any) => {
          notify(successData?.message || 'Template has successfully generated!', 'success');
          localStorage.setItem('isFromList', 'false')
          dispatch(setAIGeneratedURL(successData))
          navigate('/edit');
        },
        onError: ({ response: { data: errData }, response }: any) => {
          if(response?.status===405 || response?.status===403 || response?.status===501 ){
            notify('Session expired.', 'error')
            setTimeout(()=>{
              navigate('/auth/login')
              localStorage.clear()
            }, 2000);
          }else{
            notify(errData?.message || 'Something went wrong.', 'error')
          }
          
        },
      })
    }
  }

  // Function to handle input change
  const handleInputChange = (index: any, value: any) => {
    const newInputValues = [...inputValues];
    newInputValues[index] = value;
    setInputValues(newInputValues);
  
  };

  useEffect(() => {
    if (card) {
      let processedText = card?.prompt;
      inputValues.forEach((value, index) => {
        processedText = processedText.replace(/\*\*\*\*/, value);
      });
      setFinalText(processedText);
    }
  }, [inputValues, card]);



  // Function to calculate input width dynamically
  const calculateInputWidth = (value: any) => {
    return value?.length * 8 + 35;
  };


  /** Get all cards API */
  const { cardList }: any = useSelector<any>((state) => state.user);

  // handle get card data
  useEffect(() => {
    if(cardList){
    const cardIdFromParams = location.state?.cardId;
    const cardIdFromLocalStorage = parseInt(localStorage.getItem('cardId') || '', 10);
    const selectedCard = cardList.find((card: CardData) => card.templateID === (isNaN(cardIdFromLocalStorage) ? cardIdFromParams : cardIdFromLocalStorage));
    setCard(selectedCard)
    setSegments(selectedCard?.prompt?.split('****'))
    setHiddenText(selectedCard?.hiddenText)
  }
  }, [cardList])

  useEffect(() => {
    const areAllInputsFilled = segments && inputValues?.slice(0, segments?.length - 1)?.every(value => value.trim() !== '');

    setIsDisableBtn(!areAllInputsFilled);
  }, [inputValues,segments])

  const onSuccess =(data: any)=>{
    setData(data)
  }

  const onError =({ response: { data: errData }, response }: any)=>{
    if(response?.status===405 || response?.status===403 || response?.status===501 ){
      notify('Session expired.', 'error')
      setTimeout(()=>{
        navigate('/auth/login')
        localStorage.clear()
      }, 2000);         
    }
  }

  
  /** Get all star points API */
  const { refetch:getStarPoints, isLoading, isRefetching } = useGetAllStarPoints(onSuccess,onError)

  useEffect(() => {
    getStarPoints()
  }, [])
  

  return (
    <div className='w-full placeholder:h-full flex flex-col justify-center pt-[36px] px-5 pb-[60px]'>
      <div className="flex w-full flex-col text-[#01060D] text-left">
        <div className="headings-font headings-font text-[32px] leading-[45px] font-medium pb-[1px]">AI Designer</div>
        <div className="captions-font text-[14px] font-light pr-3">Fill the blanks to generate a design of your wish with AI.</div>
      </div>

      <div className="mt-[50px] gap-2 overflow-y-auto h-full pb-[40px]">

        <div className='w-full flex bg-white h-auto !px-3 py-2 shadow-md flex-wrap items-center overflow-x-auto'>
          <p>
            {segments && segments.map((segment, index) => (
              <Fragment key={index}>
                {segment}
                {index < segments.length - 1 && (
                  <input
                    maxLength={16}
                    value={inputValues[index]}
                    onChange={(e) => handleInputChange(index, e.target.value)}
                    type="text"
                    placeholder=""
                    style={{ minWidth: '100px', width: calculateInputWidth(inputValues[index]) + 'px' }}
                    className='mr-1 pr-1 outline-none !border-b !border-b-[#00BF1F] !rounded-none'
                  />
                )}
              </Fragment>
            ))}
          </p>
        </div>


        <Button  
          appearance="aiGradient" 
          className='mt-[40px]' 
          isLoading={getAiImageLoading}
          onClick={()=>{
            handleClick()
          }} disabled={isDisableBtn}
        >
          Generate Template
        </Button>

        <div className='text-[#8B8B8B] text-[10px] pt-3 text-center w-full font-extralight captions-font'>*Removes watermark</div>
        <div className='text-[#8B8B8B] text-[10px] pt-[2px] text-center w-full font-extralight captions-font'>*1 Credit will be deducted per AI Image generated</div>

      {(isLoading || isRefetching) ? 
        <div className='flex h-full justify-center items-center mt-10'>
          <Loader appearance='black' />
        </div>
        :
      <div className="flex flex-col  mt-10 text-center text-[12px] text-gray-600 font-light">
        <span>Credit Balance : {data?.credit_count || 0}</span>
        <span>Star Points : {data?.data?.redeemableBalance || 0}</span>
      </div>
       }

      </div>

      
    </div>
  )
}

export default CustomizedPage
