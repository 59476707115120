import { useQuery } from "react-query";
import { get } from "framework/api/http";

/* Get verify user */
export const getVerifyUser = async () => {
    const res = await get(`/user-api/verify_user`)
    return res?.data || []
  }

/* Use Query as a Hook */
export const useGetVerifyUser = (onSuccess: any, onError: any) => {
    return useQuery('user-verify', getVerifyUser, {
      refetchOnWindowFocus: false,
      enabled: false,
      retry:1,
      onSuccess,
      onError,
    })
  }

export default useGetVerifyUser;


