import { useQuery } from "react-query";
import { get } from "framework/api/http";

/* Get star points */
export const getAllStarPoints = async () => {
    const res = await get('/credits-api/get_credits')
  
    return res?.data || []
  }

/* Use Query as a Hook */
export const useGetAllStarPoints = (onSuccess: any, onError: any) => {
    return useQuery('starPoints', getAllStarPoints, {
      // refetchOnWindowFocus: true,
      enabled: false,
      retry:1,
      onSuccess,
      onError,
    })
  }

export default useGetAllStarPoints;


