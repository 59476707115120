import starPoint from 'assets/starPoint.png'
import { Button, Input, Loader } from 'components/atoms'
import { useAddStarPoints, useGetAllStarPoints } from 'framework/api/methods'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'

export const MenuPage = () => {
  const navigate =useNavigate()
  const token = localStorage.getItem('token')
  const [data, setData] = useState<any>(null)
  const [isAddCreditView, setIsAddCreditView] = useState(false)
  const [starPointCount, setStarPointCount] = useState('')
  
  const notify = (message: any, type: any) => {
    if (type === 'error') {
      toast.error(message, {
        position: 'bottom-center',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
    }
    if (type === 'success') {
      toast.success(message, {
        position: 'bottom-center',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
    }
  }

  const onSuccess =(data: any)=>{
    setData(data)
  }

  const onError =({ response: { data: errData }, response }: any)=>{
    if(response?.status===405 || response?.status===403 || response?.status===501 ){
      notify('Session expired.', 'error')
      setTimeout(()=>{
        navigate('/auth/login')
        localStorage.clear()
      }, 2000);
              
    }else{
      notify(errData?.message || 'Something went wrong.', 'error')
    }
  }

  
  /** Get all star points API */
  const { refetch:getStarPoints, isLoading: getStarPointsIsLoading, isRefetching } = useGetAllStarPoints(onSuccess,onError)

  useEffect(() => {
    getStarPoints()
  }, [])

  const { mutate: addStarPointsMutate, isLoading: addStarPointsIsLoading } = useAddStarPoints()

  // function to add star points
  const addStarPoints=()=> {
    addStarPointsMutate({
      starPointCount
    }, {
    onSuccess: ({ data: successData }: any) => {
      getStarPoints()
      setStarPointCount('')
      setIsAddCreditView(false)
      notify(successData?.message || 'OTP has been sent successfully', 'success')
    },
    onError: ({ response: { data: errData }, response }: any) => { 
      if(response?.status===405 || response?.status===403 || response?.status===501 ){
        notify('Session expired.', 'error')
        setTimeout(()=>{
          navigate('/auth/login')
          localStorage.clear()
        }, 2000);
                
      }else{
        notify(errData?.message || 'Something went wrong.', 'error')
      }
      
    },
  })
}

  return (
    <div className='w-full placeholder:h-full flex flex-col justify-center pt-[36px] px-5 pb-[60px]'>
      <div className="flex w-full flex-col text-[#01060D] text-left">
        <div className="headings-font text-[32px] leading-[45px] font-medium pb-[1px]">Credit Balance</div>
        <div className="captions-font text-[14px] font-light">Convert your Star Points into credits below.</div>
      </div>

      {(getStarPointsIsLoading || isRefetching)? 
        <div className='flex h-full justify-center items-center mt-10'>
          <Loader appearance='black' />
        </div>
      :
      <div className="flex flex-col  mt-10 text-center text-[14px] font-light">
        <span>Credit Balance : {data?.credit_count || 0}</span>
        <span>Star Points : {data?.data?.redeemableBalance || 0}</span>
      </div>
      }

      <div className="mt-[4px] gap-2 overflow-y-auto h-full w-full flex flex-col justify-center items-center ">
        <img src={starPoint} alt='gt' className='w-[60%] h-auto rounded'/>
        <div className="text-[14px] font-light">1 Credit = 5 Star Points = 1 AI Image</div>

        
        {!isAddCreditView &&
          <Button appearance="gradient" className='mt-[10px] w-[80%]' 
            onClick={()=>{
             setIsAddCreditView(true)
          }}>
              Add Credits
          </Button>}
          {isAddCreditView && 
          <div className='w-full px-10'>

          <Input value={starPointCount}  onChange={(e: any)=>{
            const inputValue = e.target.value;

            if (/^\d*$/.test(inputValue)) {
            setStarPointCount(inputValue)
            }
          }} placeholder='Enter credit top-up amount' className='mt-[10px]' />
          <div className='flex flex-col'>
          <Button 
            appearance="gradient" 
            isLoading={addStarPointsIsLoading}
            className='mt-[10px]' 
            disabled={!starPointCount}
            onClick={()=>{
              addStarPoints()
            }}
          >
              Add
          </Button>

          <Button appearance="gradient" className='mt-[10px]' 
            onClick={()=>{
              setIsAddCreditView(false)
          }}>
              Cancel
          </Button>


          </div>
          </div>
          }

      </div>
      
    </div>
  )
}

export default MenuPage
