/* eslint-disable jsx-a11y/no-autofocus */
import { FC } from 'react'
import CN from 'classnames'

export interface WishInputProps {
  className?: string | undefined
  isAutoFocus?: boolean
  onChange?: (e: any) => void | undefined
  onKeyPress?: (e: any) => void | undefined
  placeholder?: string
  onIconClick?: (e: any) => void | undefined
  value?: any
}

export const WishInput: FC<WishInputProps> = ({
  className,
  isAutoFocus,
  onChange,
  onKeyPress,
  placeholder,
  onIconClick,
  value
}: WishInputProps) => {
  return (
    <div className={CN('flex flex-col w-full relative justify-center items-end text-white', {}, className)}>
      <textarea
        placeholder={placeholder}
        autoFocus={isAutoFocus}
        maxLength={80}
        value={value}
        onChange={onChange}
        onKeyPress={onKeyPress}
        rows={2}
        className={CN(
          'w-full hidescrl text-center outline-none border border-gray-200 rounded-full !text-white py-[20px] pl-[18px] pr-[60px] text-base bg-[#3D3D3D] bg-opacity-[44%] h-[70px] placeholder:text-white',
        )}
      />
      <div className='w-[50px] h-[50px] flex justify-center items-center bg-[#FFFFFF] bg-opacity-[40%] rounded-full absolute right-2'>
        <i className="ri-arrow-right-line text-[24px] text-white" onClick={onIconClick}/>
      </div>
    </div>
  )
}

WishInput.defaultProps = {
  className: undefined,
  onChange: undefined,
  onKeyPress: undefined,
  placeholder: undefined,
  onIconClick: undefined,
  value: undefined,
}

export default WishInput
