import { useNavigate } from 'react-router-dom'



export const TermAndConditions= () => {
  const navigate = useNavigate()
  
  return (
    <div className='flex flex-1 flex-col h-auto min-h-full w-full bg-white text-black p-5 md:hidden sm:hidden'>
      <div className="w-full flex">
        <i className="ri-close-line text-[24px]" 
        onClick={()=>{
          navigate('/')
        }}
        />
      </div>

      <div className='flex flex-col mt-5 font-sans'>
        <span className='text-2xl font-bold'>T&C and Privacy Note Content:</span>
        <span className='text-xl mt-4 font-semibold'>Terms & Conditions:</span>

        <span className='text-base mt-2'><span className='font-semibold'>1.</span> In order to utilize the AI greeting card service, users are required to convert 5 Star Points into 1 Credit. Upon consenting to the conversion of Star Points, 5 Star Points will automatically be deducted from the user’s mobile number.</span>
        <span className='text-base mt-2'><span className='font-semibold'>2.</span> Once converted, the Credit balance cannot be reverted back to Star Points.</span>
        <span className='text-base mt-2'><span className='font-semibold'>3.</span> Each image generation using AI Designer will consume 1 Credit from the balance.</span>
        <span className='text-base mt-2'><span className='font-semibold'>4.</span> Users must maintain a minimum balance of 100 Star Points at the time of redemption.</span>
        <span className='text-base mt-2'><span className='font-semibold'>5.</span> All terms and conditions concerning Star Points can be accessed at <a href='https://www.dialog.lk/tc/starpoints' className='text-blue-500 underline'>https://www.dialog.lk/tc/starpoints</a> and are applicable.</span>
        <span className='text-base mt-2'><span className='font-semibold'>6.</span> Users are accountable for providing the accurate Mobile Number and OTP to utilize the service and complete redemptions.</span>
        <span className='text-base mt-2'><span className='font-semibold'>7.</span> Dialog reserves the right to suspend or terminate users from the promotion at its discretion. Dialog bears no responsibility for any fraudulent transactions conducted during the promotional period.</span>
        <span className='text-base mt-2'><span className='font-semibold'>8.</span> Pricing for Credit conversion and AI greeting card services may be subject to change based on necessity. Please check the in-app credit balance page for more information on any updates.</span>
        <span className='text-base mt-2'><span className='font-semibold'>9.</span> Dialog Axiata PLC bears no liability or responsibility for any content produced through AI designer, as it is solely prompted and controlled by the User.</span>

        <span className='text-xl mt-4 font-semibold'>Privacy Notice:</span>
        <span className='text-base mt-2'>
        • Dialog will handle any personal data provided by users in compliance with the Privacy Notice accessible at <a href='https://www.dialog.lk/privacy-notice' className='text-blue-500 underline'>https://www.dialog.lk/privacy-notice</a>.
        </span>
      </div>
    </div>
  )
}

export default TermAndConditions
