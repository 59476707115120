import { useMutation } from 'react-query'
import { postAPI } from 'framework/api/http'

interface CheckUserInterface {
  mobile: string
}

export const useCheckUser = () => {
  const mutate = useMutation(({ mobile }: CheckUserInterface) => {
    return postAPI('/user-api/check_user', {
      mobile
    })
  })

  return mutate
}

export default useCheckUser
