import dialogPrism from 'assets/dialogPrism.png'
import { Button, WishInput, Modal } from 'components/atoms'
import bardIcon from 'assets/bardIcon.png'
import { useEffect, useLayoutEffect, useRef, useState } from 'react'
import CN from 'classnames'
import { useLocation, useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux';
import isValidLink from '../../../utils/isLinkValid'
import wallet from 'assets/wallet.svg'

interface CardData {
  templateID: number;
  imageURL: string;
  prompt: string;
  hiddenText: string;
  colourCode: string;
}
export const PersonalizePage = () => {

  const containerRef: any = useRef(null)
  const location = useLocation();

  const [wishMessage, setWishMessage] = useState(localStorage.getItem('isFromList')==='true' ? '' : localStorage.getItem('wishMessage') )
  const [isFromList, setIsFromList] = useState(false);
  const [generatedImageURL, setGeneratedImageURl] = useState<string>('');
  const [card, setCard] = useState<CardData>();
  const [isLinkValid, setLinkValid] = useState(false);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false)
  


  /** Get all cards API */
  const { cardList, aiGeneratedUrl  }: any = useSelector<any>((state) => state.user);

  // handle get card data
  useEffect(() => {
    if(cardList){
    const cardIdFromParams = location.state?.cardId;
    const cardIdFromLocalStorage = parseInt(localStorage.getItem('cardId') || '', 10);
    const selectedCard = cardList?.find((card: CardData) => card.templateID === (isNaN(cardIdFromLocalStorage) ? cardIdFromParams : cardIdFromLocalStorage));
    setCard(selectedCard)
  }
  }, [cardList])

  useEffect(() => {
    const aiGeneratedTime = localStorage.getItem("aiGeneratedTime")
    if (aiGeneratedTime !== null) {
      const isTaskValid: boolean = isValidLink(aiGeneratedTime);
      setLinkValid(isTaskValid)
    }

  }, [isLinkValid])


  useEffect(() => {
    localStorage.setItem('wishMessage', '');
    const fromList = localStorage.getItem('isFromList');
    if (fromList !== null) {
      setIsFromList(JSON.parse(fromList));
    }
    if (aiGeneratedUrl !== null ) {
      setGeneratedImageURl(aiGeneratedUrl);
    }
  }, [isFromList, location, aiGeneratedUrl])



  const navigate = useNavigate()

  return (
    <div className='w-full placeholder:h-full flex flex-col justify-center pt-[36px] px-5 pb-[60px]'>
      <div className="flex w-full flex-col text-[#01060D] text-left">
        <div className="headings-font text-[32px] leading-[45px] font-medium pb-[1px]">Personalize</div>
        <div className="captions-font text-[14px] font-light">Include your heartfelt message on your card.</div>
      </div>

      <div className="mt-[50px] gap-2 overflow-y-auto h-full pb-[40px]">
        <WishInput
          value={wishMessage}
          onChange={(event) => {
            setWishMessage(event.target.value);
          }}
          
          placeholder='Type your wish here'
          onIconClick={() => {
            wishMessage && navigate('/preview', { state: { cardId: card?.templateID, imageUrl: isFromList ? card?.imageURL : generatedImageURL , wishMessage} });
            wishMessage && localStorage.setItem('wishMessage', wishMessage);
          }}
          className='mb-6'
        />

        <div
          ref={containerRef}
          className={CN('flex flex-col bg-white shadow-md', {
            'px-4 pt-4 pb-4': wishMessage
          })}

        >
          <img src={isFromList ? card?.imageURL : generatedImageURL} alt='' className='w-full h-auto rounded' />

          <div className='flex justify-between items-end'>
            {wishMessage &&
              <div className={CN("w-full whitespace-pre-line pt-3 pb-3 px-1 text-center text-black font-extralight text-[16px]",{
                'pl-[29px]':isFromList
              })}>{wishMessage}</div>
            }
            {isFromList && wishMessage &&
              <img src={dialogPrism} alt='' className='h-[34px] rounded mt-2' />
            }
          </div>
          
        </div>
        <div>
        </div>
        {isFromList && 
        <>
          <div className="text-[14px] font-light text-center w-full mt-[40px]">Create your own card with AI</div>
          <Button appearance="aiGradient" className='mt-[6px]'  onClick={() => {
             setIsConfirmationModalOpen(true)
          }}>
            <div className='flex'>AI Designer
              <img src={bardIcon} alt='bardicon' className='w-[14px] h-[14px] ml-1' />
            </div>
          </Button>

          <div className='text-[#8B8B8B] text-[10px] pt-3 text-center w-full font-extralight captions-font'>*Removes watermark</div>
        </>}

      </div>

      <Modal
        isActive={isConfirmationModalOpen}
        onClickSecondaryBtn={() => {
          navigate('/menu')
          setIsConfirmationModalOpen(false)
          
        }}
        primaryButtonProps={{
          // isFullWidth: true,
          className:'!h-full mr-0 w-[75%]',
        }}
       
        onClickPrimaryBtn={() => {
          setIsConfirmationModalOpen(false)
          navigate('/custom', { state: { isFromList: true, cardId: card?.templateID } })
        }}
        onOverlayClick={() => {
          setIsConfirmationModalOpen(false)
        }}
        modalProps={{
          style: { width: '80%' },
        }}
        secondaryButtonProps={{
          appearance:'outline',
          className:'!mr-0 mt-2 w-[75%] border-gray-500 border-[2px]'
        }}
        secondaryButtonTitle ={
          <div className='flex text-center w-full justify-center items-center'>Go to credit page
            <img src={wallet} alt='walletImg' className='w-[22px] h-[22px] ml-2'/>
          </div>
          }
        footerAlignment='horizontal'
        footerButtonAlignment='between'
        isHeaderShow={false}
        primaryButtonTitle='Ok'
        >
        <div className='flex flex-col justify-center items-center p-6'>
          <span className='text-gray-900 text-[14px] text-center font-medium'>
          Prior to utilizing AI Designer template, make sure to convert Star Points to Credits via Credit page.
          <br/>
          <br/>
          <span className='text-xs leading-none'>
            *Each AI image will deduct 1 Credit, and the conversion from Star Points to Credits cannot be reversed.
         </span>
          </span>
        </div>
      </Modal>

    </div>
  )
}

export default PersonalizePage
