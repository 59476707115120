import { Button, Input } from "components/atoms"
import { useCheckUser } from "framework/api/methods"
import { useState } from "react"
import { useNavigate } from "react-router-dom"
import { toast } from 'react-toastify'


export const Login = () => {
  const navigate = useNavigate()
  const [phone, setPhone] = useState('')
  const [isInputValid, setInputValid] = useState(false)

  const { mutate: checkUsertMutate, isLoading: checkUsertIsLoading } = useCheckUser();
  const notify = (message: any, type: any) => {
    if (type === 'error') {
      toast.error(message, {
        position: 'bottom-center',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
    }
    if (type === 'success') {
      toast.success(message, {
        position: 'bottom-center',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
    }
  }
  // handle input change
  const handleChange = (e: any) => {
    const inputValue = e.target.value;

    if (/^\d*$/.test(inputValue)) {
      const isValidFormat = /^07\d{8}$/.test(inputValue);
    setInputValid(isValidFormat);
    
    setPhone(e.target.value)
    }
   
  }
  // function to check user exists or not
  const handleCheckUser = () => {
    checkUsertMutate({
      mobile: phone
    }, {
      onSuccess: ({ data: successData }: any) => {
        notify(successData?.message || 'OTP has been sent successfully', 'success')
        navigate('/auth/request-otp', { state: { phone, ref: successData.serverRef, isNewUser: false } })
      },
      onError: ({ response: { data: errData } }: any) => {
        if (errData?.message === 'User not found!') {
          navigate('/auth/register', { state: { phone } })
        }else{
          notify(errData?.message || 'Something went wrong.', 'error')
        }
      },
    })
  }

  return (
    <div className='w-full flex flex-col justify-start items-start p-5 h-full'>
      <div className="flex w-full flex-col pt-[63px] text-[#01060D] text-left">
        <div className="headings-font text-[32px] leading-[45px] font-medium pb-[1px]">Welcome!</div>
        <div className="captions-font text-[14px] font-light">Enter the below details</div>
      </div>

      <div className="flex flex-col w-full mt-[86px]">
        <Input
          value={phone}
          onChange={handleChange}
          placeholder='07XXXXXXXX'
          labelValue='Phone number'
        />

        <Button appearance="default"
          className='mt-10'
          disabled={phone.length !== 10 || !isInputValid}
          isLoading={checkUsertIsLoading}
          onClick={() => {
            phone && handleCheckUser()
          }}
        >Login</Button>


      </div>


    </div>
  )
}

export default Login
